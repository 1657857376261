html {
  font-size: 10px;
}

body {
  font-size: 1.45em;
}

ion-app {
  max-width: 1000px;
  margin: auto;
}

.app-version {
  display: flex;
  justify-content: center;
}

.MuiAvatar-root {
  &.member:not(.jejik) img {
    filter: blur(3px);
  }
}

.mui-dialog .MuiDialog-paperWidthSm {
  max-width: 80%;
  min-width: 250px;
}

@media print {
  body {
    font-size: 1rem;
  }
  ion-content * {
    color: #000 !important;
  }
  .MuiListSubheader-root {
    // text-align: center;
    font-size: 20px !important;
  }
  ion-title {
    text-align: center;
    font-size: 24px !important;
  }
  .info .phone {
    padding-right: 0 !important;
  }
  .info .email {
    font-size: 0.9em;
  }
  .phone > svg, .email > svg {
    display: none;
  }
  .MuiList-root.MuiList-padding {
    grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
  }
  .member-box {
    height: 160px;
    margin-bottom: 20px;
  }
  .search-wrapper {
    padding: 4px !important;
  }
}